@use '../base/_colorscheme' as c
@use '../base/_responsive-sizing' as res

#gameboy
    padding: calc( res.$default-padding   )
    font-family: Pixelify Sans
    padding: 0 0 10px 0
    overflow: hidden
    // flex-wrap: wrap


    & container
        background-color: inherit

    #animate-game
        // flex-grow: 1
        // align-self: stretch
        margin: 0 10px 0 10px
        border: 5px solid c.$secondary-color-4
        border-radius: 10px
    
    #animate-instructions
        align-self: center
        // flex-shrink: 3

#instructions
    align-self: stretch
    margin: 10px
    background-color: c.$secondary-color
    color: c.$primary-color

    .ab-span
        color: c.$error-color
    .dpad-span
        color: c.$gold
    
    .button-info
        padding-left: 10px

    .instructions 
        // align-self: stretch
        b
            color: c.$pink
        p
            font-size:  calc(res.$body-font-size + 2px)
            text-align: justify

.gameboy-decorations
    border-radius: 10px
    justify-self: stretch
    align-self: stretch
    margin: 10px 15px 10px 15px
    border: 2px solid c.$white-2
    height: 15px
    background-color: c.$white

    .left-decor
        flex: 0 1 auto
        background-color: #3b4252
        border-radius: inherit
    
    .right-decor
        flex: 1 1 auto

    h1
        color: black

.game-screen-container
    height: 30vw
    width: 30vw
    min-width: 400px
    min-height: 400px
    padding: 10px
    border-radius: 10px 10px 30px 10px
    margin: 0px 10px 0px 10px
    z-index: 5
    box-shadow: 0 10px rgba(0, 0, 0, 0.2)

    .game-screen
        // background-color: #d8dee9 
        width: 95%
        height: 95%
        align-self: center
        background-color: c.$white-3
        border: 5px solid c.$secondary-color-3
        border-radius: 10px 
        box-shadow: inset 0 0 10px black
        color: black

.game-controls
    margin: 30px 10px 10px 10px
    padding: 0 1vw 0 1vw
    justify-content: space-between
    align-self: stretch
    align-items: center

    button:hover
        background-color: white

    .grid-item 
        
        background-color: c.$gold
        display: flex
        justify-content: center
        align-items: center
        // border: 1px solid black 
    
        &:hover
            // background-color: blue
            // cursor: pointer
    

    .d-pad 
        display: grid
        grid-template-columns: repeat(3, 40px) 
        grid-template-rows: repeat(3, 40px)

        .circle
            border-radius: 50%
            background-color: c.$gold
            width: 90%
            height: 90%
            box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.5)


        .right-button
            border-radius: 0px 10px 10px 0px
            box-shadow: 10px 0 20px rgba(0, 0, 0, 0.2)

            &:hover
                cursor: pointer
                background-color: white
        

        .left-button
            border-radius: 10px 0px 0px 10px
            box-shadow:  -10px 0 20px rgba(0, 0, 0, 0.1)

            &:hover
                cursor: pointer
                background-color: white
        

        .up-button
            border-radius: 10px 10px 0px 0px
            box-shadow:  0px -10px 20px rgba(0, 0, 0, 0.1)

            &:hover
                cursor: pointer
                background-color: white
        

        .down-button
            border-radius: 0px 0px 10px 10px
            box-shadow:  0px 10px 20px rgba(0, 0, 0, 0.1)
            
            &:hover
                cursor: pointer
                background-color: white
        


    .ab-buttons
        display: grid
        grid-template-columns: repeat(2, 50px)
        grid-template-rows: repeat(1, 50px)
        transform: rotate(-45deg)
        gap: 10px
        

        & .circle
            border-radius: 50%
            background-color: c.$secondary-color-4
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2)
            border: 2px solid c.$secondary-color-2

            button
                width: 100%
                height: 100%
                color: c.$error-color
                font-weight: 900
                font-size: large
            

    .speakers
        height: 7.5vh
        gap: 10px


        .speaker-line
            height: 100%
            width: 10px
            background-color: c.$secondary-color-3
            border: 0.5px solid c.$secondary-color
            box-shadow: inset 0 -5px 10px rgba(0,0,0,0.1)
            border-radius: 10px

    .placeholder
        background: none
        border: none



@media only screen and (max-device-width: 700px)
    #gameboy
        flex-direction: column
        padding: 0 

        // & container

    .game-screen-container
        width: 80vw
        height: 80vw
    
    #instructions
        // flex-grow: 0

@media (max-width: 900px)
    #gameboy
        flex-direction: column
        padding: 0 

    .game-screen-container
        width: 80vw
        height: 80vw
        min-height: 0
        min-width: 0