@use '../base/_colorscheme' as c

.arrow-container
    height: 10

.arrow
    width: 30px
    height: 50px
    position: relative

.arrow::after
    content: ''
    position: absolute
    top: 0
    left: 50%
    width: 0
    height: 0
    border-left: 10px solid transparent 
    border-right: 10px solid transparent 
    border-top: 15 px solid c.$primary-color-2
    transform: translateX(-50%)
    animation: bounce 350ms 5-- alternate

@keyframes bounce
    0%
        transform: translateX(-50%) translateY(10px)
    100%
        transform: translateX(-50%) translateY(0)

