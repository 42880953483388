@use '../base/_colorscheme' as c
@use '../base/_responsive-sizing' as res

#Projects
    background-color: c.$secondary-color
    color: c.$white
    overflow-y: scroll
    padding: res.$default-padding
    gap: 10px


    &::-webkit-scrollbar
        display: none

    // #Featured
    //     justify-content: self

@media only screen and (max-device-width: 700px)
    #Projects
        padding: calc(res.$default-padding / 2)