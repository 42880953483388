@use './_colorscheme' as c

html
    scroll-behavior: smooth
    overflow-x: hidden
body
    overflow-x: hidden
    overflow-y: auto
    font-family: Poppins
    scrollbar-width: none
    margin: 0

    #root
        min-height: 100vh
        width: 100%
        overflow-y: auto
        background-color: c.$secondary-color
        scrollbar-width: none
        overflow-x: hidden
        overflow-y: auto

        &::-webkit-scrollbar
            display: none
            -webkit-user-select: none
            -moz-user-select: none
            user-select: none
