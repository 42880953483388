// Font sizing
$body-font-size: calc(15px + 0.390625vw)
// Padding
$default-padding: 2em

body, p
    font-size: $body-font-size

    h1
        font-size: 1.7rem
        padding: 0
        margin: 0

    h2
        font-size: 1.4rem
        padding: 0
        margin: 0
    h3
        font-size: 1.3rem


