// Using Nord palette from www.nordtheme.com

// Frost / Primary colors
$primary-color: #88c0d0
$primary-color-2: #5e81ac
$primary-color-3: #81a1c1
$primary-color-4: #8fbcbb

// Polar Night / Secondary colors
$secondary-color: #2e3440
$secondary-color-2: #3b4252
$secondary-color-3: #434c5e
$secondary-color-4: #4c566a

// Snow Storm White colors
$white: #eceff4
$white-2: #e5e9f0
$white-3: #d8dee9


// Aurora / Other colors
$success-color: #a3be8c
$error-color: #bf616a
$warning-color: #d08770
$gold: #ebcb8b
$pink: #b48ead

