@use '../base/_colorscheme' as c
@use '../base/_responsive-sizing' as res

@mixin navigation-item
    padding: 10px 5px 10px 5px
    font-size: res.$body-font-size 
    margin: 2.5px
    color: c.$white
    border-radius: 5px
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3)
    backdrop-filter: blur(16px)
    

.topnav 
    position: fixed
    top: 0
    right: 0
    z-index: 10
    // min-height: 5vh
    // height: 5vh
    justify-content: flex-end
    z-index: 10
    opacity: 0
    // animation defined in _hero.sass
    // TODO move all animations to one file
    animation: fade-in 0.25s 3s linear forwards

    .nav-menu 
        // flex-direction: column
        display: flex
        list-style-type: none
        padding: 0px
        margin: 5px 0.5vw 0 5px
        // opacity: 1

        .button-no-decor.hidden
            display: none

        // button:not(#menu-button)
        //     display: block
        
        // #menu-button
        //     display: block
            
        button
            @include navigation-item
            border-radius: 5px
            border: 2px solid c.$secondary-color
            // background-color: c.$secondary-color-2

            a, i
                text-decoration: none
                color: inherit

            &:hover
                background-color: c.$secondary-color
                color: c.$gold


        &:last-child 
            margin-right: 5px
        
        i
            opacity: 1
            font-size: res.$body-font-size
            padding: 10px
            // font-weight: 900


@media only screen and (max-device-width: 700px)
    .nav-menu
        flex-direction: column
        
@media (max-width: 900px)

    .nav-menu
        flex-direction: column
    

    