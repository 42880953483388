@use '../base/_colorscheme' as c 

.svg-button
    width: 32px
    height: 32px
    padding: 0 5px 0 5px
    a
        height: 100%
        width: 100%
        color: inherit
        text-decoration: none
        
    i
        font-size: 2em
        
        color: c.$primary-color-2
