@use '../base/_colorscheme' as c
@use '../base/_responsive-sizing' as res

#Skills
    background-color: c.$secondary-color
    padding: res.$default-padding
    flex-direction: row
    gap: res.$default-padding
    justify-content: center
    // align-items: center
    // overflow-x: scroll
    flex-wrap: wrap
        

    #animate-table
        display: flex
        flex-direction: row
        height: fit-content
        align-items: flex-start
        // align-items: center
        justify-content: center
        gap: 10px
        
        table
            // border-right: 1px solid white

        .line
            align-self: center
            height: 200px
            width: 5px
            background-color: c.$pink
            border-radius: 5px


        th, td
            text-align: center
        h3
            color: c.$primary-color


@media only screen and (max-device-width: 700px)
    #Skills
        gap: calc(res.$default-padding/2)
        padding: calc(res.$default-padding/2)
        justify-content: center
        align-items: center

        #animate-table
            flex-direction: column
            align-items: center
            gap: 5px
            // justify-content: center

            .line
                width: 50px
                height: 10px
            th, td
                text-align: center
        // flex-direction: column
        // @include background-gradient(0deg)

@media (max-width: 700px)
    #Skills
        flex-direction: column
        gap: calc(res.$default-padding/2)
        padding: calc(res.$default-padding/2)
        justify-content: center
        align-items: center

        #animate-table
            flex-direction: column
            align-items: center
            justify-content: center

            th, td
                // text-align: center