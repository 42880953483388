@use '../base/_colorscheme' as c
footer
    display: flex
    align-items: center
    // flex-wrap: wrap
    justify-content: space-between
    background-color: c.$secondary-color-2
    color: c.$white-2
    
    & a
        text-decoration: none
        color: inherit
        justify-self: center

    & .copyright
        margin: 5px
        padding: 10px
    & .contacts
        font-size: 12px
        padding: 10px
        margin: 5px

@media (max-width: 700px)
    footer
        flex-direction: column

@media only screen and (max-device-width: 700px)
    footer
        flex-direction: column