.flex-container
    display: flex

.flex-container-centered
    display: flex
    align-items: center
    justify-content: center

.column
    flex-direction: column

.center-align
    align-items: center

.grid-container
    display: grid

.circular
    border-radius: 50%

.fill-parent
    width: 100%
    height: 100%

.snapping
    scroll-snap-align: start
    // scroll-snap-stop: always

.snapping-center
    scroll-snap-align: center
    // scroll-snap-stop: always

.no-snapping
    scroll-snap-align: none
