@use '../base/_colorscheme' as c

.section-separator
    background-color: c.$secondary-color
    z-index: 5
    margin: 10px

    &::-webkit-scrollbar
        display: none

    #section-title
        padding: 0
        justify-content: flex-start
        flex-grow: 0
        width: fit-content
        

        h1
            padding: 0 10px 0 10px
