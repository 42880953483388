@use '../base/_colorscheme' as c


.banner
  background-color: c.$gold
  color: c.$secondary-color
  padding: 10px

  a
    text-decoration: none

